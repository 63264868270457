.voices {
  margin-bottom: 80px;
}

.title {
  margin: 0 0 50px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}

.light {
  color: #403d39;
}

.dark {
  color: #ced4da;
}

@media only screen and (max-width: 800px) {
  .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    margin-top: 20px;
    font-size: 26px;
  }
}

@media only screen and (max-width: 480px) {
  .title {
    font-size: 22px;
  }
}
