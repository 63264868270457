@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Readex Pro";
  src: url("./fonts/ReadexPro-ExtraLight.woff2") format("woff2"),
    url("./fonts/ReadexPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./fonts/ReadexPro-SemiBold.woff2") format("woff2"),
    url("./fonts/ReadexPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./fonts/ReadexPro-Bold.woff2") format("woff2"),
    url("./fonts/ReadexPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./fonts/ReadexPro-Medium.woff2") format("woff2"),
    url("./fonts/ReadexPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./fonts/ReadexPro-Regular.woff2") format("woff2"),
    url("./fonts/ReadexPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./fonts/ReadexPro-Light.woff2") format("woff2"),
    url("./fonts/ReadexPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  font-family: "Readex Pro", "Arial", sans-serif;
  font-weight: 400;
  background-color: #212529;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.light {
  background-color: #fffcf2;
}

.stop {
  overflow: hidden;
}

main {
  flex-grow: 1;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  object-fit: cover;
}

.container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}
