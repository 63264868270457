.header {
  position: relative;
  box-shadow: rgba(101, 101, 101, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  .link__line {
    background: #ced4da;
  }

  .header__link {
    color: #ced4da;
    &:nth-child(1):hover {
      color: #eaeaea;
      .link__line {
        width: 100%;
        background: #eaeaea;
      }
    }

    &:active {
      color: rgba(234, 234, 234, 0.5);
      .link__line {
        background: #eaeaea;
      }
    }
  }
  .header__searchIcon {
    color: #ced4da;
  }

  .header__search {
    color: #ced4da;
    background: #343a40;

    &::placeholder {
      color: #ced4da;
    }
  }

  .header__tel,
  .header__insta,
  .dark__light {
    color: #ced4da;
    background-color: #343a40;
  }
}

.headerWhite {
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  .link__line {
    background: #3f3f3f;
  }

  .header__link {
    color: #403d39;
    &:nth-child(1):hover {
      color: #403d39;
      .link__line {
        width: 100%;
        background: #232323;
      }
    }

    &:active {
      color: rgba(63, 63, 63, 0.75);
      .link__line {
        background: #232323;
      }
    }
  }
  .header__searchIcon {
    color: #403d39;
  }
  .header__search {
    color: #403d39;
    background: #ccc5b9;

    &::placeholder {
      color: #403d39;
    }
  }

  .header__tel,
  .header__insta,
  .light__dark {
    color: #403d39;
    background-color: #ccc5b9;
  }
}

.header__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.header__logoLink {
  position: relative;
  z-index: 99;
  display: block;
  text-decoration: none;
}

.header__image {
  display: block;
  width: 50px;
  height: 50px;
}

.header__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 30px;
}

.header__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link__line {
  display: block;
  width: 20px;
  height: 2px;
  transition: all 0.3s ease;
}

.header__link {
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-decoration: none;
}

.header__linkList {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search__box {
  position: relative;
  width: 214px;
  height: 38px;
}

.header__searchIcon {
  position: absolute;
  top: 11px;
  right: 12px;
  transition: all 0.2s ease;
}

.header__search {
  width: 100%;
  padding: 8px 36px 8px 12px;
  border-radius: 15px;
  border: none;
  transition: all 0.2s ease;

  &::-webkit-search-clear-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  &::-ms-reveal {
    display: none;
  }

  &::placeholder {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
  }
}

.header__tel,
.header__insta {
  display: block;
  padding: 5px;
  border-radius: 11px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.header__tel {
  padding: 7.57px;
}

.mobile {
  position: relative;
  display: none;
}

.lang {
  cursor: pointer;
}

.text {
  padding: 7px 1rem;
  font-size: 16px;
  border-radius: 0.375rem;
}

.lightToggle {
  color: #403d39;
  background-color: #ccc5b9;
}

.darkToggle {
  color: #adb5bd;
  background-color: #343a40;
}

.theme__toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  cursor: pointer;
  border-radius: 11px;
  transition: all 0.2s ease;
}

@media only screen and (max-width: 800px) {
  .headerWhite,
  .header {
    margin-bottom: 40px;
  }
  .header__list {
    display: none;
  }
  .mobile {
    display: block;
    position: "absolute";
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0.9;
    display: "flex";
    align-items: "center";
    width: "100%";
  }

  .header__item {
    width: 100%;
    margin-bottom: 30px;
    &:last-child {
      .header__linkList {
        margin-left: auto;
      }
    }
  }
}
