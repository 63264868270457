.category__section {
  padding: 80px 0;

  .category__firstName,
  .category__description {
    color: #403d39;
  }

  .category__insta,
  .category__similar,
  .category__share {
    color: #403d39;
    background-color: #ccc5b9;
  }
}

.category__sectionDark {
  padding: 80px 0;

  .category__firstName {
    color: #ced4da;
  }
  .category__description {
    color: #ced4da;
  }
  .category__insta,
  .category__similar,
  .category__share {
    color: #bcc1c7;
    background-color: #343a40;
  }
}

.category__box {
  display: flex;
  flex-direction: column;
}

.category__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 40px;
  margin-bottom: 100px;
}

.category__image {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 20px;
}

.category__content {
  width: 100%;
  max-width: 650px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category__nameBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 12px;
}

.category__firstName {
  margin: 0;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  max-width: 480px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category__linkList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 5px;
}

.category__insta,
.category__similar,
.category__share {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  padding: 4px;
  border-radius: 11px;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.category__description {
  margin: 0 0 24px;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
}

.category__video {
  width: 100%;
  height: 245px;
  border-radius: 10px;

  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}

.category__canter {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.category__video2 {
  display: none;
}

.modalLight {
  div:nth-child(2) {
    background-color: #ccc5b9;
    button,
    div {
      color: #403d39;
      &:hover {
        color: #403d39;
      }
    }
  }
  .copy__btn {
    color: #eae0d5;
    background-color: #69655f;
  }
}

.modalDark {
  div:nth-child(2) {
    background-color: #343a40;
    button,
    div {
      color: #d9d9d9;
      &:hover {
        color: #d9d9d9;
      }
    }
  }
  .copy__btn {
    color: #d9d9d9;
    background-color: #212529;
  }
}

.copy__btn {
  width: 100%;
  display: block;
  margin-top: 30px;
  padding: 7px 25px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}

@media only screen and (max-width: 1115px) {
  .category__firstName {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1000px) {
  .category__firstName {
    font-size: 22px;
  }
  .category__insta,
  .category__similar,
  .category__share {
    width: 32px;
    height: 32px;
    padding: 4px;
  }
}

@media only screen and (max-width: 950px) {
  .category__top {
    margin: 0 auto 50px;
  }
  .category__image {
    margin: 0 auto;
  }
  .category__nameBox {
    align-items: flex-start;
    flex-direction: column;
  }

  .category__video {
    display: none;
    width: 0;
    height: 0;
  }
  .category__video2 {
    display: block;
    width: 100%;
    height: 245px;
    margin-bottom: 60px;
    border-radius: 10px;

    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }
  }
}

@media only screen and (max-width: 855px) {
  .category__image {
    width: 350px;
  }
}

@media only screen and (max-width: 777px) {
  .category__firstName {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .category__image {
    width: 250px;
  }
}

@media only screen and (max-width: 550px) {
  .category__top {
    flex-direction: column;
  }
  .category__image {
    width: 350px;
  }
  .category__firstName {
    flex-direction: row;
  }
  .category__content {
    height: auto;
  }
}

@media only screen and (max-width: 400px) {
  .category__image {
    width: 280px;
  }
  .category__firstName {
    display: flex;
    flex-direction: column;
  }
}
