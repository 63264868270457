.loading__box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fffcf2;
  z-index: 100;
}

.loading__boxDark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #212529;
  z-index: 100;
}

.loading__box,
.loading__boxDark {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
