.hero__sectionDark {
  position: relative;
  padding: 80px 0 50px;

  .hero__title,
  .hero__text,
  .hero__btn {
    color: #ced4da;
  }
}

.hero__section {
  position: relative;
  padding: 80px 0 50px;

  .hero__title,
  .hero__text,
  .hero__btn {
    color: #403d39;
  }
}

.hero__boxTop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 50px;
  flex-wrap: wrap;
}

.hero__left {
  width: 100%;
  max-width: 40%;
}

.hero__title {
  margin: 0 0 12px;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.hero__text {
  margin: 0 0 24px;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
}

.hero__btn {
  display: block;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.hero__image {
  display: block;
  width: 368px;
  height: 368px;
  border-radius: 15px;
}

@media only screen and (max-width: 800px) {
  .hero__boxTop {
    gap: 20px;
  }
  .hero__title {
    font-size: 24px;
  }
  .hero__text {
    font-size: 14px;
  }
  .hero__btn {
    font-size: 16px;
  }
  .hero__image {
    width: 280px;
    height: 280px;
  }
}

@media only screen and (max-width: 650px) {
  .hero__left {
    max-width: 70%;
    text-align: center;
  }
  .hero__title {
    font-size: 22px;
  }
  .hero__text {
    font-size: 14px;
    margin-bottom: 0;
  }
  .hero__btn {
    font-size: 15px;
  }
  .hero__image {
    width: 340px;
    height: 300px;
  }
}

@media only screen and (max-width: 596px) {
  .hero__section,
  .hero__sectionDark {
    padding-bottom: 10px;
  }
  .hero__section {
    .hero__title,
    .hero__text,
    .hero__btn {
      color: #ced4da;
    }
  }
  .hero__left {
    max-width: 100%;
  }
  .hero__title {
    font-size: 24px;
  }
  .hero__text {
    font-size: 16px;
  }
  .hero__btn {
    font-size: 18px;
  }
}

@media only screen and (max-width: 415px) {
  .hero__boxTop {
    gap: 10px;
  }
  .hero__left {
    text-align: center;
  }
  .hero__title {
    font-size: 22px;
  }
  .hero__text {
    font-size: 14px;
  }
  .hero__btn {
    font-size: 16px;
  }
}
