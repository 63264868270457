.voice__list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 0 40px;
  padding: 0;
  list-style-type: none;
  gap: 40px;
}

.voice__item {
  position: relative;
  width: 100%;
  max-width: 260px;
  border-radius: 20px;
}

.voice__voice {
  display: none;
}

.voice__itemLink {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  border-radius: 15px;
}

.voice__img {
  width: 100%;
  height: 300px;
  border-radius: 15px;
}

.voice__content {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 100%) 0%,
    rgba(0, 0, 0, 70%) 60%,
    rgba(0, 0, 0, 0%) 100%
  );
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.voice__textContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 170px;
}

.voice__firstname,
.voice__lastname {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  text-align: start;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ced4da;
}

.voice__play {
  display: block;
  width: 40px;
  height: 40px;
  color: #ced4da;
  border-radius: 50%;
  cursor: pointer;
}

.voice__more {
  display: block;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
}

.voice__moreLight {
  color: #403d39;
}

.voice__moreDark {
  color: #ced4da;
}

.voice__pageNation {
  display: flex;
  justify-content: flex-end;
}

.pageNation__light {
  nav > ul > li > button {
    color: #403d39;
    border-color: #403d39;
  }
}

.pageNation__dark {
  nav > ul > li > button {
    color: #ced4da;
    border-color: #ced4da;
  }
}

.empty__color {
  color: #ced4da;
}

.empty__dark {
  color: #403d39;
}

.no__data {
  font-size: 18px;
  color: #ced4da;
}

.data_dark {
  color: #403d39;
}

@media only screen and (max-width: 600px) {
  .voice__item {
    max-width: 200px;
  }
  .voice__img {
    height: 240px;
  }
  .voice__content {
    padding: 16px 12px;
  }
  .voice__textContent {
    max-width: 130px;
  }
}

@media only screen and (max-width: 500px) {
  .voice__list {
    gap: 20px;
  }
}

@media only screen and (max-width: 460px) {
  .voice__item {
    max-width: 280px;
  }
  .voice__img {
    height: 300px;
  }
  .voice__content {
    padding: 30px 30px;
  }
  .voice__textContent {
    max-width: 95px;
  }
  .voice__firstname,
  .voice__lastname {
    font-size: 16px;
  }
  .voice__play {
    width: 35px;
    height: 35px;
    svg {
      width: 35px;
      height: 35px;
    }
  }
}
