.voices__section {
  padding: 80px 0 110px;
}

.title {
  margin: 0 0 50px;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}

.light {
  color: #403d39;
}

.dark {
  color: #ced4da;
}

@media only screen and (max-width: 1000px) {
  .voices__section {
    padding: 60px 0 90px;
  }
}

@media only screen and (max-width: 800px) {
  .voices__section {
    padding: 40px 0 70px;
  }
}
